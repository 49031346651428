@import "../variables";

.project-card-description {
  position: absolute;
  max-width: 800px;

  .vertical-line {
    left: -24px;
    height: 0px;
  }

  @media screen and (max-width: $mobile-max) {
    position: static;
    margin: 24px 30px;

    h3 {
      margin-top: 0px;
      font-size: 17px;
    }
  }
}
.project-card-description-content-container {
  @media screen and (max-width: $mobile-max) {
    max-width: 490px;
    margin-left: auto;
    margin-right: auto;
  }
}

.project-card-description-dark {
  color: $text-color-dark;
}

.project-card-description-title {
  opacity: 0;
  margin-top: 8px;
}

.project-card-description-text {
  opacity: 0;
  text-align: justify;
}

.project-card-description-tag-wrapper {
  opacity: 0;
  display: flex;
  margin: 16px 0px 12px;
  @media screen and (max-width: $mobile-small-max) {
    display: none;
  }
}

.project-card-description-tag {
  display: flex;
  align-items: top;
  p {
    margin-left: 8px;
    line-height: normal;
  }
}

.project-card-description-tag-design-team:hover {
  border-style: hidden;
}

.project-card-description-tag-design-team:not(.project-card-description-tag-design-team-open):hover {
  cursor: pointer;
  opacity: 0.8;
}

.project-card-description-dark {
  .project-card-description-tag-design-team:not(.project-card-description-tag-design-team-open):hover {
    opacity: 0.5;
  }
}

.project-card-description-tag-icon {
  font-size: 1.2rem !important;
  margin-left: 4px;
  transform: translateY(-2px);
}

.project-card-description-tag:last-of-type {
  .project-card-description-tag-icon {
    font-size: 1.3rem !important;
  }
}

.project-card-description-tag:not(:first-of-type) {
  margin-left: 18px;
}

.project-card-description-tag-designer-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.project-card-description-tag-designer-wrapper > p::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 26px;
  width: 0px;
  border-bottom: 1px solid;
  @media screen and (max-width: $tablet-max) {
    width: 0px;
  }
}

.project-card-description-tag-designer-wrapper-open > p::after {
  width: 92px;
  transition: width 0.8s;
  @media screen and (max-width: $tablet-max) {
    width: 80px;
  }
}

.project-card-description-tag-design-team-wrapper {
  opacity: 0;
  position: absolute;
  top: 32px;
  p {
    white-space: nowrap;
    font-weight: 100;
    line-height: 1.8em;
  }
}

.project-card-description-tag-design-team-wrapper-show {
  opacity: 1;
  transition: opacity 1.5s;
}

.project-card-description-tag-design-team-wrapper-show.project-card-description-tag-design-team-wrapper-horizontal {
  transition-delay: 0.4s;
}

.project-card-description-tag-design-team-wrapper-horizontal {
  width: calc(100vw - 465px);
  top: -2px;
  left: 100px;

  @media screen and (max-width: $tablet-max) {
    width: calc(100vw - 430px);
    top: -1px;
    left: 88px;
  }
  p {
    float: left;
  }
  p:not(:last-of-type):after {
    content: ",";
  }
}

/* Animation */
.animate-description-static {
  .project-card-description-title,
  .project-card-description-text,
  .project-card-description-tag-wrapper {
    opacity: 1;
  }
}
.animate-description-in-1 {
  .project-card-description-title {
    animation: fadein1 0.7s forwards;
    animation-delay: 0.6s;
  }
  .project-card-description-text,
  .project-card-description-tag-wrapper {
    animation: fadein1 1s forwards;
    animation-delay: 0.8s;
  }

  .vertical-line {
    animation: linedowndescription1 0.8s forwards;
    animation-delay: 0.8s;
  }
}

.animate-description-in-2 {
  .project-card-description-title {
    animation: fadein2 0.7s forwards;
    animation-delay: 0.6s;
  }
  .project-card-description-text,
  .project-card-description-tag-wrapper {
    animation: fadein2 1s forwards;
    animation-delay: 0.8s;
  }

  .vertical-line {
    animation: linedowndescription2 0.8s forwards;
    animation-delay: 0.8s;
  }
}

.animate-description-out {
  .project-card-description-title,
  .project-card-description-text,
  .project-card-description-tag-wrapper {
    opacity: 0;
  }

  .vertical-line {
    height: 0px;
  }
}

.animate-description-static {
  .project-card-description-title,
  .project-card-description-text,
  .project-card-description-tag-wrapper {
    opacity: 1;
  }

  .vertical-line {
    height: 100%;
  }
}

@keyframes fadein1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes linedowndescription1 {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}

@keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes linedowndescription2 {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
