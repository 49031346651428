@import "./variables";

body,
html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color-dark;
  color: $text-color-light;
}

#root {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

section {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
p,
ul,
li {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 36px;
  font-family: "Arvo", serif;
  font-weight: 300;
  margin-bottom: 16px;
}

h2 {
  font-size: 28px;
  font-weight: 300;
  margin: 24px 0px 16px;
}

h3 {
  font-size: 22px;
  font-weight: 400;
  margin: 16px 0px 8px;
}

h4 {
  font-weight: normal;
  font-size: 18px;
}

li,
p {
  font-size: 14px;
  line-height: 30px;
}

.text-small {
  font-size: 12px;
}

.text-extra-small {
  font-size: 8px;
}

.text-emphasized {
  font-size: 15px;
  font-weight: 500;
}

.text-large {
  font-size: 16px;
}

ul {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}

/* Buttons */

.link-button {
  cursor: pointer;
  padding: 4px 12px;
  border: 1px solid $text-color-light;
  background: none;
  color: $text-color-light;
  font: inherit;
}

.no-style-button {
  font: inherit;
  color: inherit;
  background: inherit;
  border: none;
  outline: none;
  cursor: text;
}

.link-button:focus {
  outline: 0;
}

.link-button:hover {
  background-color: $background-color-light-hover;
}

.link-button-narrow {
  padding: 4px 8px;
}

.link-button-underline {
  border: none;
  border-bottom: 1px solid;
}

.link-button-dark {
  color: $text-color-dark;
  border: 2px solid $text-color-dark;
}

.link-button-dark:hover {
  background-color: $background-color-light-hover;
}

.icon-button {
  display: flex;
  align-items: center;

  h3 {
    margin: 4px 8px;
    font-weight: 300;
  }
}

.icon-button-space {
  justify-content: space-between;
}

.clickable-text-element {
  color: $element-color-light;
}

.clickable-text-element:hover {
  cursor: pointer;
  color: $element-color-light-hover;
}

.clickable-text-element-dark {
  color: $element-color-dark;
}

.clickable-text-element-dark:hover {
  color: $element-color-dark-hover;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.disabled-button {
  cursor: text;
}
.disabled-button:hover {
  background: none;
}

/* Elements */

.horizontal-line {
  position: absolute;
  border-top: 1px solid;
  width: 100%;
}

.vertical-line {
  position: absolute;
  width: 1px;
  height: 0px;
  border-left: 1px solid;
}

.vertical-line-right {
  border-left: 0px;
  border-right: 1px solid;
}

.hide-element {
  display: none !important;
}

.scrollable-element {
  overflow: scroll;
}

@media screen and (max-width: $tablet-max) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
  }

  h3 {
    font-size: 19px;
    font-weight: 500;
  }

  h4 {
    font-size: 16px;
  }

  li,
  p {
    font-size: 12px;
    line-height: 28px;
  }

  .text-small {
    font-size: 10px;
  }

  .text-emphasized {
    font-size: 13px;
  }

  .text-large {
    font-size: 14px;
  }
}
@media screen and (max-width: $mobile-max) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  li,
  p {
    font-size: 11px;
    line-height: 24px;
  }

  .text-small {
    font-size: 10px;
  }

  .text-emphasized {
    font-size: 13px;
  }

  .text-large {
    font-size: 14px;
  }
}

.dont-justify-last {
  text-align-last: left !important;
}
