@import "../variables";

$title-element-left-start: 4px;
$title-element-1-left-end: 199px;
$title-element-1-top-start: 78px;
$title-element-1-top-end: 198px;
$title-element-2-left-end: 243px;
$title-element-2-top-start: 118px;
$title-element-2-top-end: 288px;
$title-element-3-left-end: 134px;
$title-element-3-top-start: 158px;
$title-element-3-top-end: 348px;

$title-element-tablet-left-start: 4px;
$title-element-1-tablet-left-end: 2px;
$title-element-1-tablet-top-start: 66px;
$title-element-1-tablet-top-end: 209px;
$title-element-2-tablet-left-end: 123px;
$title-element-2-tablet-top-start: 106px;
$title-element-2-tablet-top-end: 293px;
$title-element-3-tablet-left-end: 37px;
$title-element-3-tablet-top-start: 146px;
$title-element-3-tablet-top-end: 349px;

$text-wrapper-width: 510px;
$text-wrapper-width-plus-padding: 542px;
$text-wrapper-width-tablet: 410px;
$text-wrapper-width-tablet-plus-padding: 438px;

.information-section-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.information-section-about {
  opacity: 0;
  margin-bottom: -50px;
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobile-max) {
    flex-grow: unset;
    height: calc(100% - 110px);
  }
}

.information-section-about-content-wrapper {
  display: flex;

  @media screen and (max-width: $mobile-max) {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 40px;
    flex-direction: column;
    align-items: center;
  }
}

.information-section-about-header-wrapper {
  @media screen and (max-width: $mobile-max) {
    width: 100%;
    max-width: 490px;
  }
}

.information-section-about-image,
.information-section-about-image-wrapper {
  height: 180px;
  width: 180px;

  @media screen and (max-width: $tablet-max) {
    height: 160px;
    width: 160px;
    border-radius: 50%;
  }
  @media screen and (max-width: $mobile-max) {
    height: 120px;
    width: 120px;
  }
}

.information-section-about-image-wrapper {
  overflow: hidden;
  border: 2px solid $text-color-light;
}

.information-section-about-image {
  object-fit: cover;
  object-position: 0px -10px;
  @media screen and (min-width: $desktop-min) {
    object-position: -5px -18px;
    height: 190px;
    width: 190px;
  }
}

.information-section-about-text-wrapper {
  position: absolute;
  width: $text-wrapper-width;
  text-align: justify;
  margin-bottom: -50px;
  margin-left: -75px;

  @media screen and (max-width: $tablet-max) {
    width: $text-wrapper-width-tablet;
    margin-left: 0px;
  }

  @media screen and (max-width: $mobile-max) {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
  }
}

.information-section-about-introduction-title {
  margin-top: 10px;
  @media screen and (max-width: $tablet-max) {
    margin-top: 4px;
  }
  @media screen and (max-width: $mobile-max) {
    width: fit-content;
    white-space: nowrap;
    position: absolute;
    top: 36px;
    left: calc(50% - 104px);
  }

  @media screen and (max-width: $mobile-medium-max) {
    left: 167px;
  }
  @media screen and (max-width: $mobile-extra-small-max) {
    left: 160px;
  }
}

.information-section-about-text-wrapper-placeholder {
  width: $text-wrapper-width-plus-padding;
  height: 420px;
  @media screen and (max-width: $tablet-max) {
    width: $text-wrapper-width-tablet-plus-padding;
  }

  @media screen and (max-width: $mobile-max) {
    display: none;
  }
}

.information-section-about-text-wrapper-expanded {
  .information-section-about-introduction-title {
    background-color: $background-color-light-selected;
  }
}

.information-section-about-introduction-label {
  position: absolute;
  left: $title-element-left-start;
  z-index: 2;
  white-space: nowrap;

  p {
    line-height: normal;
  }
  padding: 4px 9px;

  @media screen and (max-width: $mobile-max) {
    padding: 3px 7px;
    left: calc(50% - 84px);

    &:nth-of-type(2) {
      top: 83px !important;
    }
    &:nth-of-type(3) {
      top: 119px !important;
    }
    &:nth-of-type(4) {
      top: 155px !important;
    }
  }

  @media screen and (max-width: $mobile-medium-max) {
    left: 186px;
  }
}

.information-section-about-introduction-label-mobile {
  padding: 2px;
  line-height: normal;
  font-weight: 600;
}

.information-section-about-introduction-label:nth-of-type(2) {
  top: $title-element-1-top-start;
}
.information-section-about-introduction-label:nth-of-type(3) {
  top: $title-element-2-top-start;
}
.information-section-about-introduction-label:nth-of-type(4) {
  top: $title-element-3-top-start;
}

@media screen and (max-width: $tablet-max) {
  .information-section-about-introduction-label:nth-of-type(2) {
    top: $title-element-1-tablet-top-start;
  }
  .information-section-about-introduction-label:nth-of-type(3) {
    top: $title-element-2-tablet-top-start;
  }
  .information-section-about-introduction-label:nth-of-type(4) {
    top: $title-element-3-tablet-top-start;
  }
}

.information-section-about-introduction-text-wrapper {
  height: calc(100vh - 354px);
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    margin: 0px;
  }
  @media screen and (max-width: $mobile-max) {
    margin-left: auto;
    margin-right: auto;
    max-width: 490px;
  }
}

.information-section-about-introduction-text {
  position: absolute;
  margin-left: 4px;
  margin-top: 16px;
  opacity: 0;
  cursor: default;
  letter-spacing: -0.01em;
  @media screen and (max-width: $mobile-max) {
    width: auto;
    padding-bottom: 8px;
    position: static;
  }
}

/* Animations */

.information-section-static {
  .information-section-about {
    opacity: 1;
    margin-left: 0px;
  }
}

.information-section-animation-enter {
  .information-section-about {
    animation: informationelemententer 0.4s forwards;
    animation-delay: 0.2s;
  }
}

.information-section-animation-exit {
  .information-section-about {
    animation: informationelementexit 0.5s forwards;
  }
}

.animate-text-wrapper-element {
  @media screen and (min-width: $desktop-min) {
    animation: movetextwrapperelement 1s forwards;
  }
}

.animate-text-wrapper-element-reverse {
  @media screen and (min-width: $desktop-min) {
    animation: movetextwrapperelementreverse 1s forwards;
  }
}

.animate-element-static {
  top: 0px;
  left: 0px;
  opacity: 1;
}

.animate-title-element-1 {
  animation: movetitleelement1 1.7s forwards;
  animation-delay: 0.4s;
  @media screen and (max-width: $tablet-max) {
    animation: movetitleelement1tablet 1s forwards;
    animation-delay: 0.1s;
  }
}

.animate-title-element-1-reverse {
  animation: movetitleelement1reverse 1.2s forwards;
  animation-delay: 0s;
  @media screen and (max-width: $tablet-max) {
    animation: movetitleelement1tabletreverse 1s forwards;
    animation-delay: 0s;
  }
}

.animate-title-element-2 {
  animation: movetitleelement2 1.5s forwards;
  animation-delay: 0.4s;
  @media screen and (max-width: $tablet-max) {
    animation: movetitleelement2tablet 1.2s forwards;
    animation-delay: 0.1s;
  }
}

.animate-title-element-2-reverse {
  animation: movetitleelement2reverse 1s forwards;
  animation-delay: 0s;
  @media screen and (max-width: $tablet-max) {
    animation: movetitleelement2tabletreverse 1.2s forwards;
    animation-delay: 0s;
  }
}

.animate-title-element-3 {
  animation: movetitleelement3 2s forwards;
  animation-delay: 0.4s;
  @media screen and (max-width: $tablet-max) {
    animation: movetitleelement3tablet 1.5s forwards;
    animation-delay: 0.1s;
  }
}

.animate-title-element-3-reverse {
  animation: movetitleelement3reverse 1.5s forwards;
  animation-delay: 0s;
  @media screen and (max-width: $tablet-max) {
    animation: movetitleelement3tabletreverse 1.5s forwards;
    animation-delay: 0s;
  }
}

.animate-text-element-in {
  cursor: auto;
  animation: fadeintextelement 1s forwards;
  animation-delay: 1.5s;
  @media screen and (max-width: $tablet-max) {
    animation-delay: 0.6s;
  }
  @media screen and (max-width: $mobile-max) {
    animation-delay: 0.2s;
  }
}

.animate-text-element-out {
  animation: fadeouttextelement 1s forwards;
  animation-delay: 0;
}

.information-section-about-image-wrapper-closed {
  @media screen and (min-width: $desktop-min) {
    animation: contractimagewrapper 1s forwards;
    animation-delay: 0s;

    .information-section-about-image {
      animation: contractimage 1s forwards;
      animation-delay: 0s;
    }
  }
}

.information-section-about-image-wrapper-open {
  @media screen and (min-width: $desktop-min) {
    animation: expandimagewrapper 1s forwards;
    animation-delay: 0s;

    .information-section-about-image {
      animation: expandimage 1s forwards;
      animation-delay: 0s;
    }
  }
}

@keyframes informationelemententer {
  from {
    opacity: 0;
    margin-bottom: -50px;
  }
  to {
    opacity: 1;
    margin-bottom: 0px;
  }
}

@keyframes informationelementexit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes movetextwrapperelement {
  from {
    margin-left: -75px;
  }
  to {
    margin-left: 0px;
  }
}

@keyframes movetextwrapperelementreverse {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: -75px;
  }
}
@keyframes movetitleelement1 {
  from {
    top: $title-element-1-top-start;
    left: $title-element-left-start;
  }
  50% {
    top: $title-element-1-top-start;
    left: $title-element-1-left-end + 50px;
  }
  to {
    top: $title-element-1-top-end;
    left: $title-element-1-left-end;
  }
}

@keyframes movetitleelement1reverse {
  from {
    top: $title-element-1-top-end;
    left: $title-element-1-left-end;
  }
  50% {
    top: $title-element-1-top-start;
    left: $title-element-1-left-end + 50px;
  }
  to {
    top: $title-element-1-top-start;
    left: $title-element-left-start;
  }
}

@keyframes movetitleelement1tablet {
  from {
    top: $title-element-1-tablet-top-start;
    left: $title-element-tablet-left-start;
  }
  50% {
    top: $title-element-1-tablet-top-start;
    left: $title-element-1-tablet-left-end + 50px;
  }
  to {
    top: $title-element-1-tablet-top-end;
    left: $title-element-1-tablet-left-end;
  }
}

@keyframes movetitleelement1tabletreverse {
  from {
    top: $title-element-1-tablet-top-end;
    left: $title-element-1-tablet-left-end;
  }
  50% {
    top: $title-element-1-tablet-top-start;
    left: $title-element-1-tablet-left-end + 50px;
  }
  to {
    top: $title-element-1-tablet-top-start;
    left: $title-element-tablet-left-start;
  }
}

@keyframes movetitleelement2 {
  from {
    top: $title-element-2-top-start;
    left: $title-element-left-start;
  }
  50% {
    top: $title-element-2-top-start + 70px;
    left: $title-element-2-left-end + 150px;
  }
  to {
    top: $title-element-2-top-end;
    left: $title-element-2-left-end;
  }
}

@keyframes movetitleelement2reverse {
  from {
    top: $title-element-2-top-end;
    left: $title-element-2-left-end;
  }
  50% {
    top: $title-element-2-top-start + 70px;
    left: $title-element-2-left-end + 150px;
  }
  to {
    top: $title-element-2-top-start;
    left: $title-element-left-start;
  }
}

@keyframes movetitleelement2tablet {
  from {
    top: $title-element-2-tablet-top-start;
    left: $title-element-tablet-left-start;
  }
  50% {
    top: $title-element-2-tablet-top-start + 70px;
    left: $title-element-2-tablet-left-end + 50px;
  }
  to {
    top: $title-element-2-tablet-top-end;
    left: $title-element-2-tablet-left-end;
  }
}

@keyframes movetitleelement2tabletreverse {
  from {
    top: $title-element-2-tablet-top-end;
    left: $title-element-2-tablet-left-end;
  }
  50% {
    top: $title-element-2-tablet-top-start + 70px;
    left: $title-element-2-tablet-left-end + 50px;
  }
  to {
    top: $title-element-2-tablet-top-start;
    left: $title-element-tablet-left-start;
  }
}

@keyframes movetitleelement3 {
  from {
    top: $title-element-3-top-start;
    left: $title-element-left-start;
  }
  50% {
    top: $title-element-3-top-end - 20px;
    left: $title-element-left-start + 30px;
  }
  to {
    top: $title-element-3-top-end;
    left: $title-element-3-left-end;
  }
}

@keyframes movetitleelement3reverse {
  from {
    top: $title-element-3-top-end;
    left: $title-element-3-left-end;
  }
  50% {
    top: $title-element-3-top-end - 20px;
    left: $title-element-left-start + 30px;
  }
  to {
    top: $title-element-3-top-start;
    left: $title-element-left-start;
  }
}

@keyframes movetitleelement3tablet {
  from {
    top: $title-element-3-tablet-top-start;
    left: $title-element-tablet-left-start;
  }
  50% {
    top: $title-element-3-tablet-top-end - 20px;
    left: $title-element-left-start + 30px;
  }
  to {
    top: $title-element-3-tablet-top-end;
    left: $title-element-3-tablet-left-end;
  }
}

@keyframes movetitleelement3tabletreverse {
  from {
    top: $title-element-3-tablet-top-end;
    left: $title-element-3-tablet-left-end;
  }
  50% {
    top: $title-element-3-tablet-top-end - 20px;
    left: $title-element-left-start + 30px;
  }
  to {
    top: $title-element-3-tablet-top-start;
    left: $title-element-tablet-left-start;
  }
}

@keyframes fadeintextelement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeouttextelement {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes expandimage {
  from {
    height: 190px;
    width: 190px;
    object-position: -5px -18px;
  }
  to {
    height: 400px;
    width: 310px;
    object-position: 0px -24px;
  }
}

@keyframes contractimage {
  from {
    height: 400px;
    width: 310px;
    object-position: 0px -24px;
  }
  to {
    height: 190px;
    width: 190px;
    object-position: -5px -18px;
  }
}

@keyframes expandimagewrapper {
  from {
    height: 180px;
    width: 180px;
  }
  to {
    height: 400px;
    width: 310px;
  }
}

@keyframes contractimagewrapper {
  from {
    height: 400px;
    width: 310px;
  }
  to {
    height: 180px;
    width: 180px;
  }
}

/* CONTACT ELEMENT */

.information-section-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  border-top: 1px solid $text-color-light;
  @media screen and (max-width: $mobile-max) {
    position: absolute;
    background-color: $background-color-dark;
    bottom: 0px;
    height: 110px;
  }
}

.information-section-contact-content-wrapper {
  display: flex;
  padding-right: 55px;
  @media screen and (max-width: $tablet-max) {
    padding-right: 53px;
  }
  @media screen and (max-width: $mobile-max) {
    padding-right: 51px;
    padding-bottom: 18px;
  }
}

.information-section-contact-icon-wrapper {
  display: flex;
  position: relative;

  img {
    align-self: center;
    opacity: 0.7;
    padding-top: 6px;
    padding-left: 10px;
    width: 18px;
    cursor: pointer;
  }

  img:hover {
    opacity: 1;
  }

  span {
    opacity: 0;
    position: absolute;
    bottom: 50px;
    left: 12px;
    border: 1px solid $text-color-light;
    padding: 4px 6px;
  }
  @media screen and (max-width: $tablet-max) {
    img {
      padding-top: 4px;
    }
  }
  @media screen and (max-width: $mobile-max) {
    img {
      width: 16px;
    }
  }
}

.information-section-contact-icon-expandable-content-container {
  position: relative;
}

.information-section-contact-icon-expandable-icon-wrapper {
  position: absolute;
  z-index: 1;

  > img {
    position: absolute;
    z-index: 1;
  }

  @media screen and (max-width: $tablet-max) {
    img {
      padding-top: 4px;
    }
  }
}

.information-section-contact-icon-expandable-icon-wrapper:first-of-type {
  left: 74px;
  @media screen and (max-width: $tablet-max) {
    left: 65px;
  }
}
.information-section-contact-icon-expandable-icon-wrapper:nth-of-type(2) {
  left: 102px;
  @media screen and (max-width: $tablet-max) {
    left: 90px;
  }
}

.information-section-contact-icon-expandable-icon-wrapper-hide {
  > img {
    display: none;
  }
}

.information-section-contact-icon-expandable-button-wrapper {
  position: absolute;
  left: -3px;
  top: -6px;
  white-space: nowrap;

  button {
    padding: 6px 12px;
    img {
      padding: 0px 10px 1px 0px;
    }
  }

  img:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: $tablet-max) {
    left: -1px;

    button {
      padding: 5px 10px;

      img {
        padding: 0px 8px 1px 0px;
      }
    }
  }

  @media screen and (max-width: $mobile-max) {
    left: -75px;
    top: 34px;
    button {
      padding: 5px 8px;

      p {
        line-height: 1em;
      }
    }
  }

  &.information-section-contact-icon-expandable-button-wrapper-email {
    @media screen and (max-width: $mobile-max) {
      left: -100px;
    }

    @media screen and (max-width: $mobile-small-max) {
      left: unset;
      right: calc(160px - 50vw);
    }
  }
}

.information-section-contact-icon-wrapper:not(:first-of-type) {
  margin-left: 48px;
  @media screen and (max-width: $tablet-max) {
    margin-left: 40px;
  }
}

/* Animations */

.animate-button-enter {
  display: flex;
  animation: iconelementin 1s forwards;
}

.animate-button-enter-left {
  animation: iconelementinleft 1s forwards;

  @media screen and (max-width: $tablet-max) {
    animation: iconelementinlefttablet 1s forwards;
  }
  @media screen and (max-width: $mobile-max) {
    animation: iconelementin 1s forwards;
  }
}

.animate-button-exit {
  opacity: 0;
  display: none;
}

.animate-move-element-left {
  animation: iconelementfadein 1s forwards;

  @media screen and (max-width: $mobile-max) {
    animation: none;
  }
}

.animate-move-element-right {
  animation: iconelementright 1s forwards;

  @media screen and (max-width: $tablet-max) {
    animation: iconelementrighttablet 1s forwards;
  }
  @media screen and (max-width: $mobile-max) {
    animation: none;
  }
}

.animate-copied-text-1 {
  animation: copiedtextshow1 1.5s forwards;
  animation-delay: 0s;
}

.animate-copied-text-2 {
  animation: copiedtextshow2 1.5s forwards;
  animation-delay: 0s;
}

@keyframes iconelementfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes iconelementin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(12px);
  }
}

@keyframes iconelementinleft {
  from {
    opacity: 0;
    transform: translateX(0px);
  }
  to {
    opacity: 1;
    transform: translateX(12px);
  }
}

@keyframes iconelementinlefttablet {
  from {
    opacity: 0;
    transform: translateX(0px);
  }
  to {
    opacity: 1;
    transform: translateX(12px);
  }
}

@keyframes iconelementright {
  from {
    opacity: 0;
    transform: translateX(152px);
  }
  to {
    opacity: 1;
    transform: translateX(164px);
  }
}

@keyframes iconelementrighttablet {
  from {
    opacity: 0;
    transform: translateX(134px);
  }
  to {
    opacity: 1;
    transform: translateX(146px);
  }
}

@keyframes copiedtextshow1 {
  from {
    opacity: 0;
    margin-bottom: 0px;
  }
  40% {
    opacity: 1;
  }
  to {
    opacity: 0;
    margin-bottom: 16px;
  }
}

@keyframes copiedtextshow2 {
  from {
    opacity: 0;
    margin-bottom: 0px;
  }
  40% {
    opacity: 1;
  }
  to {
    opacity: 0;
    margin-bottom: 16px;
  }
}
