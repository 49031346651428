@import "../variables";

.project-card-controls {
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 75px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 2;
  color: $text-color-light;

  @media screen and (max-width: $mobile-max) {
    top: 0px;
    height: 100%;
  }
}

.project-card-controls-dark {
  color: $text-color-dark;
}

.project-card-control-buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 36px;
  }
  @media screen and (max-width: $tablet-max) {
    svg {
      font-size: 32px;
    }
  }
  @media screen and (max-width: $mobile-max) {
    height: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px 0px;

    svg {
      padding: 20px 16px;
      height: 100%;
    }
  }
}

.project-card-controls-image-buttons {
  display: flex;
  margin-left: 45px;
  margin-right: 45px;

  span {
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin: 0px 5px;
    border: 1.5px solid $text-color-dark;
    background-color: $element-color-mid;
    border-radius: 50%;
    display: inline-block;
  }

  @media screen and (max-width: $tablet-max) {
    margin-left: 30px;
    margin-right: 30px;
    span {
      height: 14px;
      width: 14px;
      margin: 0px 4px;
      border: 1px solid;
    }
  }

  @media screen and (max-width: $mobile-max) {
    height: 100%;
    align-items: flex-end;
  }

  span.project-card-controls-image-active-button {
    background-color: $element-color-mid-selected;
  }

  span:not(.project-card-controls-image-active-button):hover {
    background-color: $element-color-mid-hover;
  }
}

.project-section-digital {
  @media screen and (min-width: $tablet-min) {
    .project-card-controls {
      width: $catalog-remaining-width;
      transition: width 1s;
    }
    .project-card-controls-compressed {
      width: $catalog-collapsed-remaining-width;
      transition: width 1s;
    }
  }
}

/* Animation */
.project-section-animation-static {
  .project-card-controls {
    opacity: 1;
  }
}

.project-section.project-section-animation-enter {
  .project-card-controls {
    animation: fadein1 0.8s forwards;
  }
}

.project-section.project-section-animation-exit {
  .project-card-controls {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
