@import "../variables";

.navigator {
  position: absolute;
  z-index: 4;
  left: 20px;
  width: 50px;
  top: 50%;
  transform: translateY(-50%);

  .vertical-line {
    height: 24px;
  }
  @media screen and (max-width: $mobile-max) {
    display: none;
  }
}

.navigator-hidden {
  cursor: auto;
  z-index: 0;
}

.navigator-item {
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navigator-item-text {
  display: none;
  margin-left: 9px;
}

.navigator-item-active {
  .navigator-item-text {
    display: block;
    font-weight: 600;
  }
  .vertical-line {
    border-width: 3px;
  }
}

.navigator-item:not(.navigator-item-active):hover {
  .navigator-item-text {
    display: block;
  }
}

.navigator-dark {
  color: $text-color-dark;
}

.animate-navigator-in {
  animation: fadein 0.5s forwards;
}

.animate-navigator-in-slow {
  animation-delay: 0.5s;
  animation: fadein 1s forwards;
}

.animate-navigator-out {
  animation: fadeout 0.5s forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
