/* Breakpoints */
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$desktop-min: 1024px;

$mobile-extra-small-max: 399px;
$mobile-small-max: 499px;
$mobile-medium-max: 569px;

$catalog-width: 380px;
$catalog-width-mobile: 100%;
$catalog-remaining-width: calc(100% - 380px);
$catalog-collapsed-width: 120px;
$catalog-collapsed-remaining-width: calc(100% - 120px);
$catalog-margin: 100px;
$catalog-double-margin-remaining-width: calc(100% - 580px);

/* COLORS  */
/* -- Background -- */
$background-color-black: black;
$background-color-dark: #101010;
$background-color-light: #f5f5f5;

$background-color-light-hover: rgba(
  $color: white,
  $alpha: 0.15,
);
$background-color-light-selected: rgba(
  $color: white,
  $alpha: 0.25,
);

$background-color-ultralight-hover: rgba(
  $color: white,
  $alpha: 0.4,
);
$background-color-ultralight-selected: rgba(
  $color: white,
  $alpha: 0.6,
);

$background-color-hyperlight-hover: rgba(
  $color: white,
  $alpha: 0.8,
);

$background-color-dark-cover-opaque: rgba(
  $color: #202020,
  $alpha: 0.9,
);
$background-color-dark-cover-opaque-hover: rgba(
  $color: #202020,
  $alpha: 0.7,
);
$background-color-dark-cover-transparent: rgba(
  $color: black,
  $alpha: 0.5,
);
$background-color-light-cover-transparent: rgba(
  $color: white,
  $alpha: 0.5,
);

$background-color-light-hover-no-alpha: #a9a9a9;
$background-color-ultralight-hover-no-alpha: #f0f0f0;

/* -- Element -- */
$element-color-light: #d0d0d0;
$element-color-light-hover: white;

$element-color-mid: #666666;
$element-color-mid-hover: #999999;
$element-color-mid-selected: white;

$element-color-dark: #101010;
$element-color-dark-hover: #666666;

/* -- Text -- */
$text-color-light: white;
$text-color-dark: black;
