@import "../variables";

.project-card {
  width: 100%;
  height: 100%;
  touch-action: manipulation;
  @media screen and (max-width: $mobile-max) {
    display: flex;
    flex-direction: column;

    > div:nth-child(1),
    > div:nth-child(2),
    > div:nth-child(4) {
      flex: 0 1 auto;
    }
    > div:nth-child(3) {
      height: 0px;
      flex: 1 1 auto;
    }
  }
}

.project-card-title-element-mobile {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  min-height: 60px;
  box-sizing: border-box;
  padding: 0px 30px;

  h2,
  svg {
    z-index: 3;
    margin: 0px;
  }

  h2 {
    font-weight: 400;
  }

  svg {
    font-size: 20px;
    padding: 20px;
    transform: translateX(20px);
  }

  .horizontal-line {
    z-index: 3;
    bottom: -1px;
    left: 28px;
    right: 28px;
    width: auto;
  }
}

.project-card-title-element-mobile-digital {
  flex-direction: row-reverse;
}

.project-card-image-container {
  position: relative;
  height: 100%;
}

.project-card-image-wrapper {
  display: none;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img.contain {
    width: auto;
    max-width: 100%;
    object-fit: contain;
    box-sizing: border-box;
  }

  img.contain-center {
    right: 50%;
    transform: translateX(50%);
  }

  img.project-card-image-static {
    transition: none !important;
  }

  @media screen and (max-width: $mobile-max) {
    border-top: 1px solid;
    border-bottom: 1px solid;
    box-sizing: border-box;

    img {
      display: block;
      object-fit: cover;
      margin-left: auto;
      margin-right: auto;
      position: static;
    }
  }
}

.project-card-image-wrapper-active {
  display: block;
}

/* Animation */
.animate-card-static {
  opacity: 1;
}

.animate-card-fade-in-1 {
  animation: fadein1 0.8s forwards;
}

.animate-card-fade-in-2 {
  animation: fadein2 0.8s forwards;
}

.animate-card-fade-out {
  opacity: 0;
}

@keyframes fadein1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
