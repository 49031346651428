@import "../variables";

$typist-width: 585px;
$typist-width-tablet: 507px;
$typist-height: 108px;
$typist-height-tablet: 96px;
$typist-width-mobile: 270px;
$typist-height-mobile: 144px;

.designer-section-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.designer-section-typist-wrapper {
  width: $typist-width;
  height: $typist-height;
  white-space: nowrap;

  h3 {
    font-weight: 300;
  }

  h3:first-child {
    letter-spacing: 0.005em;
  }

  h3:not(:first-child) {
    margin-top: 24px;
    letter-spacing: 0.015em;
  }

  @media screen and (max-width: $tablet-max) {
    width: $typist-width-tablet;
    height: $typist-height-tablet;
    h3:not(:first-child) {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: $mobile-max) {
    width: $typist-width-mobile;
    height: $typist-height-mobile;
    white-space: normal;

    h3 {
      margin: 0px;
      line-height: 2em;
    }
    h3:first-child {
      letter-spacing: 0.02em;
    }

    h3:not(:first-child) {
      margin-top: 0px;
      letter-spacing: normal;
    }
  }
}

.typist-button-disabled {
  .link-button {
    cursor: initial;
  }
  .link-button:hover {
    background-color: initial;
  }
}

/* Animations */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Copied from node_modules/react-typist/dist/Typist.css */

.Typist .Cursor {
  display: inline-block;
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
